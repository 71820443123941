import { RelatedLinks } from '@typings/official-directory';

export const relatedLinks: RelatedLinks[] = [
  {
    children: 'Board of directors',
    target: '_blank',
    to: '/',
    withIcon: false,
  },
  {
    children: 'Trustees',
    target: '_blank',
    to: '/',
    withIcon: false,
  },
  {
    children: 'Rotary International Committees',
    target: '_blank',
    to: '/',
    withIcon: false,
  },
  {
    children: 'The Rotary Foundation Committees',
    target: '_blank',
    to: '/',
    withIcon: false,
  },
  {
    children: 'Executive staff',
    target: '_blank',
    to: '/',
    withIcon: false,
  },
  {
    children: 'Rotary Action Groups',
    target: '_blank',
    to: '/',
    withIcon: false,
  },
  {
    children: 'Fellowships',
    target: '_blank',
    to: '/',
    withIcon: false,
  },
  {
    children: 'International Assembly Training Team',
    target: '_blank',
    to: '/',
    withIcon: false,
  },
  {
    children: 'Regional Leaders',
    target: '_blank',
    to: '/',
    withIcon: false,
  },
];
