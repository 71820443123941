import { DistrictRelatedClub } from '@typings/official-directory';

export const districtRelatedClubs: DistrictRelatedClub[] = [
  {
    id: '1',
    clubName: 'Adrian',
    districtNumber: '6400',
    clubSite: 'www.facebook.com/adriannoonrotaryclub',
    clubAddress:
      'Adrian Noon Rotary Club, 4110 Country Club Rd, Adrian, MI, 49221-8439, United States',
    clubHours: 'Wed/1930',
    contact: {
      phone: '+1 517-265-2196',
      email: 'Adrian-3021@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Carrie Dillon',
        address: '112 1/2 N Main St Apt B, Adrian, MI, 49221, United States',
        email: 'Dillon-10042360@gmail.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Charles Chase',
        address: '684 Stonecrest, Adrian, MI, 49221, United States',
        email: 'Chase-831716@gmail.com',
      },
    ],
    charterDate: '13-Mar-1998',
    memberCount: '25',
    sponsoredClubs: 'Rotaract',
  },
  {
    id: '2',
    clubName: 'Riverview',
    districtNumber: '6400',
    clubSite: 'www.facebook.com/riverviewrotaryclub',
    clubAddress:
      'Riverview Rotary Club, 456 River Rd, Riverview, MI, 49222-1234, United States',
    clubHours: 'Tue/1830',
    contact: {
      phone: '+1 517-555-0123',
      email: 'riverview-rotary@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Morgan Lee',
        address: '321 Elm St, Riverview, MI, 49222, United States',
        email: 'lee.morgan@email.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Jamie Taylor',
        address: '987 Maple Ave, Riverview, MI, 49222, United States',
        email: 'taylor.jamie@email.com',
      },
    ],
    charterDate: '15-May-2000',
    memberCount: '40',
    sponsoredClubs: 'Rotaract',
  },
  {
    id: '3',
    clubName: 'Springfield',
    districtNumber: '6400',
    clubSite: 'www.facebook.com/springfieldrotaryclub',
    clubAddress:
      'Springfield Rotary Club, 789 Springfield Blvd, Springfield, MI, 49223-4567, United States',
    clubHours: 'Mon/1900',
    contact: {
      phone: '+1 517-555-0456',
      email: 'springfield-rotary@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Taylor Reed',
        address: '654 Oak St, Springfield, MI, 49223, United States',
        email: 'reed.taylor@email.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Jordan Kim',
        address: '321 Birch St, Springfield, MI, 49223, United States',
        email: 'kim.jordan@email.com',
      },
    ],
    charterDate: '10-Jun-2002',
    memberCount: '35',
    sponsoredClubs: 'Rotary',
  },
  {
    id: '4',
    clubName: 'Lakeshore',
    districtNumber: '6400',
    clubSite: 'www.facebook.com/lakeshorerotaryclub',
    clubAddress:
      'Lakeshore Rotary Club, 123 Lakeshore Dr, Lakeshore, MI, 49224-7890, United States',
    clubHours: 'Fri/1730',
    contact: {
      phone: '+1 517-555-0678',
      email: 'lakeshore-rotary@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Alex Morgan',
        address: '456 Pine St, Lakeshore, MI, 49224, United States',
        email: 'morgan.alex@email.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Chris Jordan',
        address: '789 Cedar Ave, Lakeshore, MI, 49224, United States',
        email: 'jordan.chris@email.com',
      },
    ],
    charterDate: '20-Aug-2003',
    memberCount: '28',
    sponsoredClubs: 'Rotaract',
  },
  {
    id: '5',
    clubName: 'Maplewood',
    districtNumber: '6400',
    clubSite: 'www.facebook.com/maplewoodrotaryclub',
    clubAddress:
      'Maplewood Rotary Club, 234 Maplewood Rd, Maplewood, MI, 49225-0123, United States',
    clubHours: 'Thu/1900',
    contact: {
      phone: '+1 517-555-0890',
      email: 'maplewood-rotary@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Sam Parker',
        address: '123 Walnut St, Maplewood, MI, 49225, United States',
        email: 'parker.sam@email.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Jamie Lee',
        address: '456 Fir St, Maplewood, MI, 49225, United States',
        email: 'lee.jamie@email.com',
      },
    ],
    charterDate: '30-Sep-2004',
    memberCount: '32',
    sponsoredClubs: 'Rotary',
  },
  {
    id: '6',
    clubName: 'Hillside',
    districtNumber: '6400',
    clubSite: 'www.facebook.com/hillsiderotaryclub',
    clubAddress:
      'Hillside Rotary Club, 345 Hillside Ave, Hillside, MI, 49226-3456, United States',
    clubHours: 'Tue/1800',
    contact: {
      phone: '+1 517-555-1234',
      email: 'hillside-rotary@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Taylor Scott',
        address: '789 Cherry St, Hillside, MI, 49226, United States',
        email: 'scott.taylor@email.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Pat Morgan',
        address: '654 Spruce St, Hillside, MI, 49226, United States',
        email: 'morgan.pat@email.com',
      },
    ],
    charterDate: '12-Oct-2001',
    memberCount: '29',
    sponsoredClubs: 'Rotaract',
  },
  {
    id: '7',
    clubName: 'Cedarwood',
    districtNumber: '6400',
    clubSite: 'www.facebook.com/cedarwoodrotaryclub',
    clubAddress:
      'Cedarwood Rotary Club, 567 Cedarwood St, Cedarwood, MI, 49227-4567, United States',
    clubHours: 'Wed/1900',
    contact: {
      phone: '+1 517-555-2345',
      email: 'cedarwood-rotary@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Jamie Blake',
        address: '432 Oak Ave, Cedarwood, MI, 49227, United States',
        email: 'blake.jamie@email.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Jordan Smith',
        address: '876 Willow St, Cedarwood, MI, 49227, United States',
        email: 'smith.jordan@email.com',
      },
    ],
    charterDate: '25-Nov-2000',
    memberCount: '31',
    sponsoredClubs: 'Rotary',
  },
  {
    id: '8',
    clubName: 'Meadowview',
    districtNumber: '6400',
    clubSite: 'www.facebook.com/meadowviewrotaryclub',
    clubAddress:
      'Meadowview Rotary Club, 890 Meadowview Dr, Meadowview, MI, 49228-5678, United States',
    clubHours: 'Fri/1730',
    contact: {
      phone: '+1 517-555-3456',
      email: 'meadowview-rotary@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Alex Jordan',
        address: '123 Lake St, Meadowview, MI, 49228, United States',
        email: 'jordan.alex@email.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Sam Kim',
        address: '456 River Rd, Meadowview, MI, 49228, United States',
        email: 'kim.sam@email.com',
      },
    ],
    charterDate: '19-Jan-2005',
    memberCount: '27',
    sponsoredClubs: 'Rotaract',
  },
  {
    id: '9',
    clubName: 'Pinecrest',
    districtNumber: '6400',
    clubSite: 'www.facebook.com/pinecrestrotaryclub',
    clubAddress:
      'Pinecrest Rotary Club, 234 Pinecrest Ln, Pinecrest, MI, 49229-6789, United States',
    clubHours: 'Mon/1900',
    contact: {
      phone: '+1 517-555-4567',
      email: 'pinecrest-rotary@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Casey Brown',
        address: '567 Birch St, Pinecrest, MI, 49229, United States',
        email: 'brown.casey@email.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Jordan White',
        address: '890 Fir St, Pinecrest, MI, 49229, United States',
        email: 'white.jordan@email.com',
      },
    ],
    charterDate: '05-Feb-2006',
    memberCount: '33',
    sponsoredClubs: 'Rotary',
  },
  {
    id: '10',
    clubName: 'Sunset',
    districtNumber: '6400',
    clubSite: 'www.facebook.com/sunsetrotaryclub',
    clubAddress:
      'Sunset Rotary Club, 111 Sunset Blvd, Sunset, MI, 49230-7890, United States',
    clubHours: 'Thu/1800',
    contact: {
      phone: '+1 517-555-5678',
      email: 'sunset-rotary@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Alexis Taylor',
        address: '222 Sky St, Sunset, MI, 49230, United States',
        email: 'taylor.alexis@email.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Chris Brown',
        address: '333 Cloud St, Sunset, MI, 49230, United States',
        email: 'brown.chris@email.com',
      },
    ],
    charterDate: '15-Mar-2007',
    memberCount: '30',
    sponsoredClubs: 'Rotaract',
  },
  {
    id: '11',
    clubName: 'Greenwood',
    districtNumber: '6400',
    clubSite: 'www.facebook.com/greenwoodrotaryclub',
    clubAddress:
      'Greenwood Rotary Club, 456 Greenwood Ave, Greenwood, MI, 49231-0123, United States',
    clubHours: 'Fri/1730',
    contact: {
      phone: '+1 517-555-6789',
      email: 'greenwood-rotary@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Robin Fisher',
        address: '123 Garden St, Greenwood, MI, 49231, United States',
        email: 'fisher.robin@email.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Taylor Green',
        address: '456 Blossom St, Greenwood, MI, 49231, United States',
        email: 'green.taylor@email.com',
      },
    ],
    charterDate: '22-Apr-2008',
    memberCount: '28',
    sponsoredClubs: 'Rotary',
  },
  {
    id: '12',
    clubName: 'Valleyview',
    districtNumber: '6400',
    clubSite: 'www.facebook.com/valleyviewrotaryclub',
    clubAddress:
      'Valleyview Rotary Club, 789 Valleyview Dr, Valleyview, MI, 49232-3456, United States',
    clubHours: 'Mon/1900',
    contact: {
      phone: '+1 517-555-7890',
      email: 'valleyview-rotary@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Jamie Carter',
        address: '234 Ridge St, Valleyview, MI, 49232, United States',
        email: 'carter.jamie@email.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Casey Hall',
        address: '567 Cliff St, Valleyview, MI, 49232, United States',
        email: 'hall.casey@email.com',
      },
    ],
    charterDate: '30-Jun-2009',
    memberCount: '33',
    sponsoredClubs: 'Rotaract',
  },
  {
    id: '13',
    clubName: 'Riverbend',
    districtNumber: '6400',
    clubSite: 'www.facebook.com/riverbendrotaryclub',
    clubAddress:
      'Riverbend Rotary Club, 101 Riverbend Rd, Riverbend, MI, 49233-6789, United States',
    clubHours: 'Thu/1800',
    contact: {
      phone: '+1 517-555-8901',
      email: 'riverbend-rotary@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Morgan Lee',
        address: '345 Stream St, Riverbend, MI, 49233, United States',
        email: 'lee.morgan@email.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Jordan Taylor',
        address: '678 River St, Riverbend, MI, 49233, United States',
        email: 'taylor.jordan@email.com',
      },
    ],
    charterDate: '15-Aug-2010',
    memberCount: '26',
    sponsoredClubs: 'Rotary',
  },
  {
    id: '14',
    clubName: 'Oceanview',
    districtNumber: '6400',
    clubSite: 'www.facebook.com/oceanviewrotaryclub',
    clubAddress:
      'Oceanview Rotary Club, 234 Oceanview Dr, Oceanview, MI, 49234-7890, United States',
    clubHours: 'Wed/1800',
    contact: {
      phone: '+1 517-555-9012',
      email: 'oceanview-rotary@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Avery Johnson',
        address: '567 Beach St, Oceanview, MI, 49234, United States',
        email: 'johnson.avery@email.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Taylor Smith',
        address: '890 Coastline St, Oceanview, MI, 49234, United States',
        email: 'smith.taylor@email.com',
      },
    ],
    charterDate: '18-Sep-2011',
    memberCount: '30',
    sponsoredClubs: 'Rotary',
  },
  {
    id: '15',
    clubName: 'Canyonview',
    districtNumber: '6400',
    clubSite: 'www.facebook.com/canyonviewrotaryclub',
    clubAddress:
      'Canyonview Rotary Club, 456 Canyon Rd, Canyonview, MI, 49235-0123, United States',
    clubHours: 'Thu/1900',
    contact: {
      phone: '+1 517-555-0123',
      email: 'canyonview-rotary@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Jordan Lee',
        address: '321 Summit St, Canyonview, MI, 49235, United States',
        email: 'lee.jordan@email.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Morgan White',
        address: '654 Peak St, Canyonview, MI, 49235, United States',
        email: 'white.morgan@email.com',
      },
    ],
    charterDate: '22-Nov-2012',
    memberCount: '29',
    sponsoredClubs: 'Rotaract',
  },
  {
    id: '16',
    clubName: 'Summit',
    districtNumber: '6400',
    clubSite: 'www.facebook.com/summitrotaryclub',
    clubAddress:
      'Summit Rotary Club, 789 Summit Ave, Summit, MI, 49236-3456, United States',
    clubHours: 'Tue/1900',
    contact: {
      phone: '+1 517-555-1234',
      email: 'summit-rotary@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Casey Brown',
        address: '234 Altitude St, Summit, MI, 49236, United States',
        email: 'brown.casey@email.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Jamie Green',
        address: '567 Elevation St, Summit, MI, 49236, United States',
        email: 'green.jamie@email.com',
      },
    ],
    charterDate: '15-Jan-2013',
    memberCount: '31',
    sponsoredClubs: 'Rotary',
  },
];
