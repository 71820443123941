import { District } from '@typings/official-directory';

export const districts: District[] = [
  {
    id: '1',
    districtNumber: '6400',
    country: 'CANADA--(part of Ontario); USA--(part of Michigan);',
    zone: '28',
    occupationTitle: 'District Governer',
    name: 'Mr. Russell M. Jones',
    address: '45084 Hanford, Canton, MI, 48187, United States',
    phoneNumber: '+1 734-502-9732',
    workNumber: '+1 734-468-4257',
    emailId: 'Jones-6168999@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '2',
    districtNumber: '6420',
    country: 'USA--(Illinois);',
    zone: '29',
    occupationTitle: 'District Governer',
    name: 'Mr. David L. Emerick Jr.',
    address: '2363 44th Street, Rock Island, IL, 61201, United States',
    phoneNumber: '+1 8475306877',
    workNumber: '+1 734-468-4257',
    emailId: 'Bak-1855235@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '3',
    districtNumber: '6440',
    country: 'USA--(Illinois);',
    zone: '29',
    occupationTitle: 'District Governer',
    name: 'Mary M. Bak',
    address: '2363 44th Street, Rock Island, IL, 61201, United States',
    phoneNumber: '+1 8475306877',
    workNumber: '+1 734-468-4257',
    emailId: 'Bak-1855235@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '4',
    districtNumber: '6450',
    country: 'USA--(Illinois);',
    zone: '29',
    occupationTitle: 'District Governer',
    name: 'Conor J. Gee',
    address: '452 East Ct., Elmhurst, IL, 60126-2910, United States',
    phoneNumber: '+1 708-768-2050',
    workNumber: '+1 734-468-4257',
    emailId: 'Gee-7001210@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '5',
    districtNumber: '6460',
    country: 'CANADA--(part of Ontario); USA--(part of Michigan);',
    zone: '28',
    occupationTitle: 'District Governer',
    name: 'Mr. Russell M. Jones',
    address: '45084 Hanford, Canton, MI, 48187, United States',
    phoneNumber: '+1 734-502-9732',
    workNumber: '+1 734-468-4257',
    emailId: 'Jones-6168999@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '6',
    districtNumber: '6499',
    country: 'USA--(Illinois);',
    zone: '29',
    occupationTitle: 'District Governer',
    name: 'Janet L. Ellis-Nelson',
    address: '1905 Moraine Dr., Champaign, IL, 61822-5258, United States',
    phoneNumber: '+1 217-202-3898',
    workNumber: '+1 734-468-4257',
    emailId: 'Ellis-Nelson-3433400@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '7',
    districtNumber: '6494',
    country: 'CANADA--(part of Ontario); USA--(part of Michigan);',
    zone: '28',
    occupationTitle: 'District Governer',
    name: 'Mr. Russell M. Jones',
    address: '45084 Hanford, Canton, MI, 48187, United States',
    phoneNumber: '+1 734-502-9732',
    workNumber: '+1 734-468-4257',
    emailId: 'Jones-6168999@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '8',
    districtNumber: '6473',
    country: 'CANADA--(part of Ontario); USA--(part of Michigan);',
    zone: '28',
    occupationTitle: 'District Governer',
    name: 'Mr. Russell M. Jones',
    address: '45084 Hanford, Canton, MI, 48187, United States',
    phoneNumber: '+1 734-502-9732',
    workNumber: '+1 734-468-4257',
    emailId: 'Jones-6168999@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '9',
    districtNumber: '6484',
    country: 'CANADA--(part of Ontario); USA--(part of Michigan);',
    zone: '28',
    occupationTitle: 'District Governer',
    name: 'Mr. Russell M. Jones',
    address: '45084 Hanford, Canton, MI, 48187, United States',
    phoneNumber: '+1 734-502-9732',
    workNumber: '+1 734-468-4257',
    emailId: 'Jones-6168999@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '10',
    districtNumber: '6420',
    country: 'USA--(Illinois);',
    zone: '29',
    occupationTitle: 'District Governer',
    name: 'Mr. David L. Emerick Jr.',
    address: '2363 44th Street, Rock Island, IL, 61201, United States',
    phoneNumber: '+1 8475306877',
    workNumber: '+1 734-468-4257',
    emailId: 'Bak-1855235@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '11',
    districtNumber: '6440',
    country: 'USA--(Illinois);',
    zone: '29',
    occupationTitle: 'District Governer',
    name: 'Mary M. Bak',
    address: '2363 44th Street, Rock Island, IL, 61201, United States',
    phoneNumber: '+1 8475306877',
    workNumber: '+1 734-468-4257',
    emailId: 'Bak-1855235@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '12',
    districtNumber: '6450',
    country: 'USA--(Illinois);',
    zone: '29',
    occupationTitle: 'District Governer',
    name: 'Conor J. Gee',
    address: '452 East Ct., Elmhurst, IL, 60126-2910, United States',
    phoneNumber: '+1 708-768-2050',
    workNumber: '+1 734-468-4257',
    emailId: 'Gee-7001210@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '13',
    districtNumber: '6465',
    country: 'CANADA--(part of Ontario); USA--(part of Michigan);',
    zone: '28',
    occupationTitle: 'District Governer',
    name: 'Mr. Russell M. Jones',
    address: '45084 Hanford, Canton, MI, 48187, United States',
    phoneNumber: '+1 734-502-9732',
    workNumber: '+1 734-468-4257',
    emailId: 'Jones-6168999@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '14',
    districtNumber: '6492',
    country: 'USA--(Illinois);',
    zone: '29',
    occupationTitle: 'District Governer',
    name: 'Janet L. Ellis-Nelson',
    address: '1905 Moraine Dr., Champaign, IL, 61822-5258, United States',
    phoneNumber: '+1 217-202-3898',
    workNumber: '+1 734-468-4257',
    emailId: 'Ellis-Nelson-3433400@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '15',
    districtNumber: '6482',
    country: 'CANADA--(part of Ontario); USA--(part of Michigan);',
    zone: '28',
    occupationTitle: 'District Governer',
    name: 'Mr. Russell M. Jones',
    address: '45084 Hanford, Canton, MI, 48187, United States',
    phoneNumber: '+1 734-502-9732',
    workNumber: '+1 734-468-4257',
    emailId: 'Jones-6168999@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '16',
    districtNumber: '6481',
    country: 'CANADA--(part of Ontario); USA--(part of Michigan);',
    zone: '28',
    occupationTitle: 'District Governer',
    name: 'Mr. Russell M. Jones',
    address: '45084 Hanford, Canton, MI, 48187, United States',
    phoneNumber: '+1 734-502-9732',
    workNumber: '+1 734-468-4257',
    emailId: 'Jones-6168999@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '17',
    districtNumber: '6420',
    country: 'USA--(Illinois);',
    zone: '29',
    occupationTitle: 'District Governer',
    name: 'Mr. David L. Emerick Jr.',
    address: '2363 44th Street, Rock Island, IL, 61201, United States',
    phoneNumber: '+1 8475306877',
    workNumber: '+1 734-468-4257',
    emailId: 'Bak-1855235@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '18',
    districtNumber: '6440',
    country: 'USA--(Illinois);',
    zone: '29',
    occupationTitle: 'District Governer',
    name: 'Mary M. Bak',
    address: '2363 44th Street, Rock Island, IL, 61201, United States',
    phoneNumber: '+1 8475306877',
    workNumber: '+1 734-468-4257',
    emailId: 'Bak-1855235@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '19',
    districtNumber: '6450',
    country: 'USA--(Illinois);',
    zone: '29',
    occupationTitle: 'District Governer',
    name: 'Conor J. Gee',
    address: '452 East Ct., Elmhurst, IL, 60126-2910, United States',
    phoneNumber: '+1 708-768-2050',
    workNumber: '+1 734-468-4257',
    emailId: 'Gee-7001210@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '20',
    districtNumber: '6460',
    country: 'CANADA--(part of Ontario); USA--(part of Michigan);',
    zone: '28',
    occupationTitle: 'District Governer',
    name: 'Mr. Russell M. Jones',
    address: '45084 Hanford, Canton, MI, 48187, United States',
    phoneNumber: '+1 734-502-9732',
    workNumber: '+1 734-468-4257',
    emailId: 'Jones-6168999@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '21',
    districtNumber: '6490',
    country: 'USA--(Illinois);',
    zone: '29',
    occupationTitle: 'District Governer',
    name: 'Janet L. Ellis-Nelson',
    address: '1905 Moraine Dr., Champaign, IL, 61822-5258, United States',
    phoneNumber: '+1 217-202-3898',
    workNumber: '+1 734-468-4257',
    emailId: 'Ellis-Nelson-3433400@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '22',
    districtNumber: '6471',
    country: 'CANADA--(part of Ontario); USA--(part of Michigan);',
    zone: '28',
    occupationTitle: 'District Governer',
    name: 'Mr. Russell M. Jones',
    address: '45084 Hanford, Canton, MI, 48187, United States',
    phoneNumber: '+1 734-502-9732',
    workNumber: '+1 734-468-4257',
    emailId: 'Jones-6168999@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '23',
    districtNumber: '6461',
    country: 'CANADA--(part of Ontario); USA--(part of Michigan);',
    zone: '28',
    occupationTitle: 'District Governer',
    name: 'Mr. Russell M. Jones',
    address: '45084 Hanford, Canton, MI, 48187, United States',
    phoneNumber: '+1 734-502-9732',
    workNumber: '+1 734-468-4257',
    emailId: 'Jones-6168999@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '24',
    districtNumber: '6340',
    country: 'CANADA--(part of Ontario)',
    zone: '28',
    occupationTitle: 'District Governer',
    name: 'Mr. Russell M. Jones',
    address: '45084 Hanford, Canton, MI, 48187, United States',
    phoneNumber: '+1 734-502-9732',
    workNumber: '+1 734-468-4257',
    emailId: 'Jones-6168999@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '25',
    districtNumber: '6220',
    country: 'USA--(part of Michigan);',
    zone: '29',
    occupationTitle: 'District Governer',
    name: 'Mr. Russell M. Jones',
    address: '45084 Hanford, Canton, MI, 48187, United States',
    phoneNumber: '+1 734-502-9732',
    workNumber: '+1 734-468-4257',
    emailId: 'Jones-6168999@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '26',
    districtNumber: '6250',
    country: 'USA--(Illinois);',
    zone: '29',
    occupationTitle: 'District Governer',
    name: 'Conor J. Gee',
    address: '452 East Ct., Elmhurst, IL, 60126-2910, United States',
    phoneNumber: '+1 708-768-2050',
    workNumber: '+1 734-468-4257',
    emailId: 'Gee-7001210@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
  {
    id: '27',
    districtNumber: '6160',
    country: 'CANADA--(part of Ontario); USA--(part of Michigan);',
    zone: '28',
    occupationTitle: 'District Governer',
    name: 'Mr. Russell M. Jones',
    address: '45084 Hanford, Canton, MI, 48187, United States',
    phoneNumber: '+1 734-502-9732',
    workNumber: '+1 734-468-4257',
    emailId: 'Jones-6168999@gmail.com',
    spousePartner: 'Ms. Barbara J. Jones',
    classification: 'Financial Planner-Retired',
  },
];
