import { ClubByName } from '@typings/official-directory';

export const clubsByName: ClubByName[] = [
  {
    id: '1',
    clubName: 'Bayview Sunshine, Hong Kong',
    districtNumber: '3450',
    clubSite: 'www.rcbayviewsunshinehk.com',
    clubAddress:
      'Royal Garden Hotel, 69 Mody Rd., Tsimshatsui, Kowloon, Hong Kong',
    clubHours: 'Wed/1930',
    contact: {
      phone: '+91-3456789012',
      email: 'Bayview-Sunshine-31367@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Esther Chak',
        address:
          'Flat A, 3/F. Grand City Ct., 37 Grampian Rd., Kowloon City, Hong Kong',
        email: 'Chak-10528973@gmail.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Flora Leung',
        address:
          '2/f, 311, The Prestige,, Wang Chau Village Ext. Area,, Hong Kong, Hong Kong',
        email: 'Leung-8099493@gmail.com',
      },
      {
        id: '3',
        title: 'Executive Secretary',
        name: 'Connie Yu',
        address:
          'Marble Bldg., 10th Fl., 22 Kam Hong St., North Point, Hong Kong',
        email: 'Yu-8103855@gmail.com',
      },
    ],
    charterDate: '13-Mar-1998',
    memberCount: '19',
  },
  {
    id: '2',
    clubName: 'Bulandshar Sunshine, UP; India',
    districtNumber: '3100',
    clubSite: 'www.bulandsharsunshineup.com',
    clubAddress: '',
    clubHours: 'Sat/1900',
    contact: {
      phone: '+12 3456789012',
      email: 'Bulandhar-Sunshine-31367@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Esther Chak',
        address:
          'Flat A, 3/F. Grand City Ct., 37 Grampian Rd., Kowloon City, Hong Kong',
        email: 'Chak-10528973@gmail.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Flora Leung',
        address:
          '2/f, 311, The Prestige,, Wang Chau Village Ext. Area,, Hong Kong, Hong Kong',
        email: 'Leung-8099493@gmail.com',
      },
      {
        id: '3',
        title: 'Executive Secretary',
        name: 'Connie Yu',
        address:
          'Marble Bldg., 10th Fl., 22 Kam Hong St., North Point, Hong Kong',
        email: 'Yu-8103855@gmail.com',
      },
    ],
    charterDate: '13-Mar-1998',
    memberCount: '21',
  },
  {
    id: '3',
    clubName: 'Culcutta Sunshine, WB; India',
    districtNumber: '3123',
    clubSite: 'www.cuclutasunshinewb.com',
    clubAddress: '',
    clubHours: 'Sun/1900',
    contact: {
      phone: '+91-3456789012',
      email: 'Culcutta-Sunshine-31367@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Esther Chak',
        address:
          'Flat A, 3/F. Grand City Ct., 37 Grampian Rd., Kowloon City, Hong Kong',
        email: 'Chak-10528973@gmail.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Flora Leung',
        address:
          '2/f, 311, The Prestige,, Wang Chau Village Ext. Area,, Hong Kong, Hong Kong',
        email: 'Leung-8099493@gmail.com',
      },
      {
        id: '3',
        title: 'Executive Secretary',
        name: 'Connie Yu',
        address:
          'Marble Bldg., 10th Fl., 22 Kam Hong St., North Point, Hong Kong',
        email: 'Yu-8103855@gmail.com',
      },
    ],
    charterDate: '13-Mar-1998',
    memberCount: '21',
  },
  {
    id: '4',
    clubName: 'Dhaka Sunshine, Bangladesh',
    districtNumber: '3125',
    clubSite: 'www.dhakaasunshinebg.com',
    clubAddress: '',
    clubHours: 'Sun/1900',
    contact: {
      phone: '+922-3456789012',
      email: 'Dhaka-Sunshine-31367@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Esther Chak',
        address:
          'Flat A, 3/F. Grand City Ct., 37 Grampian Rd., Kowloon City, Hong Kong',
        email: 'Chak-10528973@gmail.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Flora Leung',
        address:
          '2/f, 311, The Prestige,, Wang Chau Village Ext. Area,, Hong Kong, Hong Kong',
        email: 'Leung-8099493@gmail.com',
      },
      {
        id: '3',
        title: 'Executive Secretary',
        name: 'Connie Yu',
        address:
          'Marble Bldg., 10th Fl., 22 Kam Hong St., North Point, Hong Kong',
        email: 'Yu-8103855@gmail.com',
      },
    ],
    charterDate: '13-Mar-1998',
    memberCount: '21',
  },
  {
    id: '5',
    clubName: 'Harbor Light Rotary, Hong Kong',
    districtNumber: '3451',
    clubSite: 'www.rcharborlighthk.com',
    clubAddress: 'Harborview Hotel, 8 Shun Tak Centre, Sheung Wan, Hong Kong',
    clubHours: 'Thu/1930',
    contact: {
      phone: '+91-3456789013',
      email: 'HarborLight-34567@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Michael Wong',
        address: 'Flat B, 4/F, Green Garden, Tsim Sha Tsui, Hong Kong',
        email: 'Wong-12345678@gmail.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Linda Chan',
        address: 'Flat 5, 2/F, Cityview Mansion, Mongkok, Hong Kong',
        email: 'Chan-87654321@gmail.com',
      },
      {
        id: '3',
        title: 'Treasurer',
        name: 'David Lee',
        address: 'Unit 9, 10/F, Harbour Plaza, Hunghom, Hong Kong',
        email: 'Lee-23456789@gmail.com',
      },
    ],
    charterDate: '22-Apr-2001',
    memberCount: '25',
  },
  {
    id: '6',
    clubName: 'Peak Friendship Club, Hong Kong',
    districtNumber: '3452',
    clubSite: 'www.rcpeakfriendship.com',
    clubAddress: 'Peak Tower, 128 Peak Rd., The Peak, Hong Kong',
    clubHours: 'Mon/1930',
    contact: {
      phone: '+91-3456789014',
      email: 'PeakFriendship-98765@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Samantha Cheung',
        address: 'Unit 6, 5/F, Gold Coast, Tuen Mun, Hong Kong',
        email: 'Cheung-34567890@gmail.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Tommy Yip',
        address: 'Flat 12, 2/F, Victoria Garden, Causeway Bay, Hong Kong',
        email: 'Yip-65432109@gmail.com',
      },
      {
        id: '3',
        title: 'Membership Chair',
        name: 'Anna Kwan',
        address: 'Flat 3, 6/F, Silver Sea, Tsim Sha Tsui, Hong Kong',
        email: 'Kwan-10987654@gmail.com',
      },
    ],
    charterDate: '05-Jun-2005',
    memberCount: '30',
  },
  {
    id: '7',
    clubName: 'Golden Dragon Club, Hong Kong',
    districtNumber: '3453',
    clubSite: 'www.rcgoldendragonhk.com',
    clubAddress: 'Gold Dragon Hotel, 3/F, Yau Ma Tei, Hong Kong',
    clubHours: 'Tue/1930',
    contact: {
      phone: '+91-3456789015',
      email: 'GoldenDragon-23456@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Raymond Tsang',
        address: 'Flat D, 1/F, Happy Valley, Hong Kong',
        email: 'Tsang-56789012@gmail.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Jenny Ho',
        address: 'Flat 14, 4/F, Ocean View, Sai Kung, Hong Kong',
        email: 'Ho-67890123@gmail.com',
      },
      {
        id: '3',
        title: 'Public Relations',
        name: 'Oliver Choi',
        address: 'Flat 8, 5/F, Harbour Heights, Kowloon, Hong Kong',
        email: 'Choi-78901234@gmail.com',
      },
    ],
    charterDate: '15-Jul-2000',
    memberCount: '22',
  },
  {
    id: '8',
    clubName: 'Emerald City Club, Hong Kong',
    districtNumber: '3454',
    clubSite: 'www.rcemeraldcityhk.com',
    clubAddress: 'Emerald City Hotel, 21 Shatin Rd., Shatin, Hong Kong',
    clubHours: 'Fri/1930',
    contact: {
      phone: '+91-3456789016',
      email: 'EmeraldCity-87654@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'Sarah Leung',
        address: 'Flat E, 2/F, Star Mansion, Yuen Long, Hong Kong',
        email: 'Leung-78945612@gmail.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Peter Fung',
        address: 'Flat C, 3/F, Meadow View, Kwai Chung, Hong Kong',
        email: 'Fung-32165498@gmail.com',
      },
      {
        id: '3',
        title: 'Community Service Chair',
        name: 'Nancy Yip',
        address: 'Flat 11, 8/F, Sunshine Tower, Tai Po, Hong Kong',
        email: 'Yip-12398765@gmail.com',
      },
    ],
    charterDate: '30-Sep-2010',
    memberCount: '28',
  },
  {
    id: '9',
    clubName: 'Silver Star Rotary, Hong Kong',
    districtNumber: '3455',
    clubSite: 'www.rcsilverstarhk.com',
    clubAddress: 'Silver Star Hotel, 45 Nathan Rd., Tsim Sha Tsui, Hong Kong',
    clubHours: 'Sat/1930',
    contact: {
      phone: '+91-3456789017',
      email: 'SilverStar-98765@gmail.com',
    },
    occupation: [
      {
        id: '1',
        title: 'President',
        name: 'William Ma',
        address: 'Flat A, 2/F, Mountain View, Sai Kung, Hong Kong',
        email: 'Ma-23456789@gmail.com',
      },
      {
        id: '2',
        title: 'Secretary',
        name: 'Emily Tsai',
        address: 'Flat 7, 4/F, Sunny Place, Fanling, Hong Kong',
        email: 'Tsai-76543210@gmail.com',
      },
      {
        id: '3',
        title: 'Event Chair',
        name: 'Hannah Ching',
        address: 'Flat 2, 6/F, City Plaza, Chai Wan, Hong Kong',
        email: 'Ching-34567890@gmail.com',
      },
    ],
    charterDate: '14-Aug-2003',
    memberCount: '23',
  },
];
