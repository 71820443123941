import * as React from 'react';
import { FC, useEffect, useState } from 'react';

import Pagination from '@rotaryintl/harris-pagination';

import {
  DISTRICTS_CURRENT_PAGE,
  DISTRICTS_PAGE_SIZE,
  DISTRICTS_PAGINATION_VARIANT,
} from '@presenters/web/components/OfficialDirectory/constants';
import { districtRelatedClubs } from '@presenters/web/components/OfficialDirectory/MockData/DistrictRelatedClubs';
import LoadingComponent from '@presenters/web/components/OfficialDirectory/Others/LoadingComponent';
import NoResultFound from '@presenters/web/components/OfficialDirectory/Others/NoResultFound';

import DistrictCardClubsResults from './DistrictCardClubsResults';

import { useTranslation } from '@external/react-i18next';

import {
  DistrictCardClubsProps,
  DistrictClubName,
  LanguageCode,
} from '@typings/official-directory';

const DistrictCardClubs: FC<DistrictCardClubsProps> = ({ searchParams }) => {
  const { t, i18n } = useTranslation();
  const langCode = i18n.language.toLowerCase() as LanguageCode;
  const [expandedCardId, setExpandedCardId] = useState<string | null>(null);
  const [searchData, setSearchData] = useState<DistrictClubName[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(
    DISTRICTS_CURRENT_PAGE
  );

  const pageSize = DISTRICTS_PAGE_SIZE;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, searchData.length);
  const currentPageData = searchData.slice(startIndex, endIndex);

  const loading = false;

  useEffect(() => {
    if (searchParams?.trim() === '') {
      setSearchData([]);
    } else {
      const filterData = districtRelatedClubs?.filter(
        district => district?.districtNumber === searchParams
      );
      setSearchData(filterData);
      setCurrentPage(DISTRICTS_CURRENT_PAGE);
    }
  }, [searchParams]);

  const onClick = (id: string) => {
    setExpandedCardId(prevId => (prevId === id ? null : id));
  };
  const onPageChange = (event: React.SyntheticEvent, pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return <LoadingComponent />;
  }

  if (!searchData || searchData.length === 0) {
    return <NoResultFound searchParams={searchParams} />;
  }

  return (
    <div className="my-6">
      <div>
        <p className="text-xs pt-2">
          {t('official.directory.district-pagination-title-1', 'Showing')}{' '}
          <span className="font-bold">{startIndex + 1}</span>-
          <span className="font-bold">{endIndex}</span>{' '}
          {t('official.directory.district-pagination-title-2', 'of')}{' '}
          <span className="font-bold">{searchData.length}</span>{' '}
          {t('official.directory.district-pagination-title-3', 'results for')}
        </p>
      </div>
      <div>
        {currentPageData.map(district => (
          <DistrictCardClubsResults
            key={district.id}
            district={district}
            expandedCardId={expandedCardId}
            onClick={onClick}
          />
        ))}
      </div>
      <div>
        <Pagination
          currentPage={currentPage}
          lang={langCode}
          onPageChange={onPageChange}
          pageSize={pageSize}
          siblingCount={DISTRICTS_CURRENT_PAGE}
          totalCount={searchData.length}
          variant={DISTRICTS_PAGINATION_VARIANT}
        />
      </div>
    </div>
  );
};

export default DistrictCardClubs;
