import React from 'react';

import Button from '@rotaryintl/harris-button';
import IconFont from '@rotaryintl/harris-icon-font';
import Link from '@rotaryintl/harris-link';

import { useTranslation } from '@external/react-i18next';

import { ClubsByNameResultsProps } from '@typings/official-directory';

const ClubsByNameResults: React.FC<ClubsByNameResultsProps> = ({
  club,
  expandedCardId,
  onClick,
}) => {
  const { t } = useTranslation();
  const {
    id,
    clubName,
    districtNumber,
    clubSite,
    clubAddress,
    clubHours,
    contact,
    occupation,
    charterDate,
    memberCount,
  } = club;
  return (
    <div key={id} className="shadow-xl rounded-xlg my-6 p-8 w-full">
      <h4 className="font-semibold text-lg-24 leading-lg">{clubName}</h4>
      <h5 className="text-azure text-lg-18 font-semibold leading-9 normal-case">
        {t(
          'official.directory.clubs-by-name-district-number',
          'District {{districtNumber}}',
          { districtNumber }
        )}
      </h5>
      <div className="flex flex-row gap-1.5 items-center">
        <IconFont
          color="#0c3d7d"
          size="16"
          className="bg-azure rounded-full club-icon-font"
          type="fas-browser"
        />
        <Link
          to={clubSite}
          target="_blank"
          className="text-azure text-md font-bold px-0"
        >
          {clubSite}
        </Link>
      </div>
      <p className="text-md">{clubAddress}</p>
      <h6 className="font-semibold text-md pt-2.5 pb-2 m-0 normal-case">
        {t('official.directory.clubs-by-name-meeting', 'Meeting day/time')}
      </h6>
      <p className="text-md">{clubHours}</p>
      <h6 className="font-semibold text-md pt-2.5 pb-2 m-0 normal-case">
        {t('official.directory.clubs-by-name-contact', 'Contact')}
      </h6>
      <div className="flex flex-row gap-1.5 items-center">
        <IconFont
          color="#0c3d7d"
          size="16"
          className="bg-azure rounded-full club-icon-font"
          type="fas-phone"
        />
        <p className="font-normal text-md leading-md py-1 my-0">
          {contact?.phone}
        </p>
      </div>
      <div className="flex flex-row gap-1.5 items-center">
        <IconFont
          color="#0c3d7d"
          size="16"
          className="bg-azure rounded-full club-icon-font"
          type="fas-envelope"
        />
        <p className="font-normal text-md leading-md py-1 my-0">
          {contact.email}
        </p>
      </div>
      {expandedCardId === id && (
        <>
          <div>
            {occupation.map(item => {
              const { id, title, name, address, email } = item;
              return (
                <div key={id} className="py-2.5">
                  <h6 className="font-semibold text-md pt-2.5 pb-2 m-0 normal-case">
                    {title}
                  </h6>
                  <p className="text-md m-0 pb-2">{name}</p>
                  <p className="text-md m-0 pb-2">{address}</p>
                  <div className="flex flex-row gap-1.5 items-center">
                    <IconFont
                      color="#0c3d7d"
                      size="16"
                      className="bg-azure rounded-full club-icon-font"
                      type="fas-envelope"
                    />
                    <p className="text-md text-azure m-0 pb-2">{email}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <h6 className="font-semibold text-md pt-2.5 pb-2 m-0 normal-case">
            {t('official.directory.clubs-by-name-charter-date', 'Charter date')}
          </h6>
          <p className="text-md">{charterDate}</p>
          <h6 className="font-semibold text-md pt-2.5 pb-2 m-0 normal-case">
            {t('official.directory.clubs-by-name-member-count', 'Member count')}
          </h6>
          <p className="text-md">{memberCount}</p>
        </>
      )}
      <div>
        <Button
          clickHandler={() => onClick(id)}
          icon={expandedCardId === id ? 'fas-angle-up' : 'fas-angle-down'}
          size="md"
          variant="text"
          className="mt-6"
          isIconLeftAligned={false}
          style={{ color: '#323C44' }}
        >
          {expandedCardId === id
            ? t('official.directory.clubs-by-name-view-less', 'View less')
            : t('official.directory.clubs-by-name-view-more', 'View more')}
        </Button>
      </div>
    </div>
  );
};

export default ClubsByNameResults;
