import * as React from 'react';
import { FC, useState } from 'react';

import RelatedLinks from '@rotaryintl/harris-related-links';
import Tabs from '@rotaryintl/harris-tabs';
import { TabInfo } from '@rotaryintl/harris-tabs/dist/tabs.props';

import ClubSection from '@presenters/web/components/OfficialDirectory/Club/ClubSection';
import DistrictSection from '@presenters/web/components/OfficialDirectory/District/DistrictSection';
import { relatedLinks } from '@presenters/web/components/OfficialDirectory/MockData/RelatedLinks';

import './styles.css';

import { useTranslation } from '@external/react-i18next';

const OfficialDirectory: FC = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<TabInfo>({
    name: t('official.directory.default-tab-label', 'District'),
    scrollTo: 0,
  });
  return (
    <div className="my-4 mobile-l:mx-10 tablet:mx-0 p-0">
      <h1 className="text-xl-38 my-0 mobile-l:text-xl-48 tablet:text-xl-58 font-semibold leading-lg-45 mobile-l:leading-xl-57 tablet:leading-xl-70 text-naturals-grey-600">
        {t('official.directory.page-heading', 'Official Directory')}
      </h1>
      <div className="flex flex-col tablet:flex-row justify-between gap-4 tablet:gap-20">
        <div className="m-0 p-0 tablet:w-7/12">
          <Tabs
            activeTabInfo={activeTab}
            onChangeTab={setActiveTab}
            tabs={[
              {
                component: <DistrictSection />,
                name: t('official.directory.district-tab-label', 'District'),
                title: t('official.directory.district-tab-title', 'District'),
              },
              {
                component: <ClubSection />,
                name: t('official.directory.club-tab-label', 'Club'),
                title: t('official.directory.club-tab-title', 'Club'),
              },
            ]}
          />
        </div>
        <div>
          <RelatedLinks
            description={t(
              'official.directory.related-links-description',
              'Read about key people who help shape your Rotary experience.'
            )}
            links={relatedLinks}
            title={t(
              'official.directory.related-links-title',
              'More of your Rotary network'
            )}
          />
        </div>
      </div>
    </div>
  );
};
export default OfficialDirectory;
