import { ClubByCity } from '@typings/official-directory';

export const clubsByCity: ClubByCity[] = [
  {
    id: '1',
    clubCity: 'Delhi Capital Rotary Club, DL; India',
    districtNumber: '3012',
    clubSite: 'www.rotarydelhicapital.com',
    address: 'Golf Club, 4th Green, Lodhi Road, New Delhi, DL, 110003, India',
    meeting: 'Fri/1930',
    contact: '+91-8800113258',
    charterDate: '12-Jun-2013',
    memberCount: 32,
    sponsoredClubs: 'Rotaract Delhi',
  },
  {
    id: '2',
    clubCity: 'South Delhi Rotary Club, DL; India',
    districtNumber: '3013',
    clubSite: 'www.rotarysouthdelhi.com',
    address:
      'The Ashok Hotel, 50-B, Chanakyapuri, New Delhi, DL, 110021, India',
    meeting: 'Mon/1930',
    contact: '+91-8800223344',
    charterDate: '15-Sep-2015',
    memberCount: 40,
    sponsoredClubs: 'Rotaract South Delhi',
  },
  {
    id: '3',
    clubCity: 'East Delhi Rotary Club, DL; India',
    districtNumber: '3014',
    clubSite: 'www.rotaryeastdelhi.com',
    address: 'Radisson Blu, Paschim Vihar, New Delhi, DL, 110063, India',
    meeting: 'Thu/1930',
    contact: '+91-8800334455',
    charterDate: '21-Mar-2000',
    memberCount: 50,
    sponsoredClubs: 'Rotract Delhi',
  },
  {
    id: '4',
    clubCity: 'West Delhi Rotary Club, DL; India',
    districtNumber: '3015',
    clubSite: 'www.rotarywestdelhi.com',
    address: 'Pind Balluchi, Paschim Vihar, New Delhi, DL, 110063, India',
    meeting: 'Tue/1930',
    contact: '+91-8800445566',
    charterDate: '01-Jan-2010',
    memberCount: 35,
    sponsoredClubs: 'Rotaract West Delhi',
  },
  {
    id: '5',
    clubCity: 'Delhi Heritage Rotary Club, DL; India',
    districtNumber: '3016',
    clubSite: 'www.rotarydelhiheritage.com',
    address:
      'India International Centre, 40, Max Mueller Marg, New Delhi, DL, 110003, India',
    meeting: 'Wed/1930',
    contact: '+91-8800556677',
    charterDate: '30-Sep-2010',
    memberCount: 28,
    sponsoredClubs: 'Rotaract Heritage Delhi',
  },
  {
    id: '6',
    clubCity: 'Delhi Green Rotary Club, DL; India',
    districtNumber: '3017',
    clubSite: 'www.rotarydelhigreen.com',
    address: 'Green Park, New Delhi, DL, 110016, India',
    meeting: 'Thu/1800',
    contact: '+91-8800667788',
    charterDate: '05-Feb-2008',
    memberCount: 45,
    sponsoredClubs: 'Rotract Green Delhi',
  },
  {
    id: '7',
    clubCity: 'Delhi North Rotary Club, DL; India',
    districtNumber: '3018',
    clubSite: 'www.rotarynorthernindia.com',
    address: 'The Leela Palace, Chanakyapuri, New Delhi, DL, 110023, India',
    meeting: 'Sat/1930',
    contact: '+91-8800778899',
    charterDate: '10-Apr-2009',
    memberCount: 33,
    sponsoredClubs: 'Rotaract North Delhi',
  },
  {
    id: '8',
    clubCity: 'Delhi South West Rotary Club, DL; India',
    districtNumber: '3019',
    clubSite: 'www.rotarysouthwestdelhi.com',
    address: 'Dwarka Sector 12, New Delhi, DL, 110078, India',
    meeting: 'Tue/1930',
    contact: '+91-8800889900',
    charterDate: '18-Aug-2011',
    memberCount: 38,
    sponsoredClubs: 'Rotrary South West Delhi',
  },
  {
    id: '9',
    clubCity: 'Capital City New Delhi, DL; India',
    districtNumber: '3012',
    clubSite: 'www.rotarycapitalcitynewdelhi.com',
    address:
      'Zimkhana Club, New Delhi, Lodi Road, New Delhi, DL, 110001, India',
    meeting: 'Fri/1930',
    contact: '+91-8800113258',
    charterDate: '12-Jun-2013',
    memberCount: 32,
    sponsoredClubs: 'Rotaract',
  },
  {
    id: '10',
    clubCity: 'Sunnyvale, CA; USA',
    districtNumber: '5010',
    clubSite: 'www.rotarysunnyvale.org',
    address:
      'Sunnyvale Community Center, 550 E. Remington Dr., Sunnyvale, CA, 94087, USA',
    meeting: 'Wed/1200',
    contact: '+1-408-555-0198',
    charterDate: '15-Sep-2015',
    memberCount: 40,
    sponsoredClubs: 'Interact',
  },
  {
    id: '11',
    clubCity: 'Tokyo, Japan',
    districtNumber: '2750',
    clubSite: 'www.rotarytokyo.org',
    address:
      'Tokyo Tower, 4-2-8 Shibakoen, Minato City, Tokyo, 105-0011, Japan',
    meeting: 'Thu/1800',
    contact: '+81-3-5555-0123',
    charterDate: '21-Mar-2000',
    memberCount: 50,
    sponsoredClubs: 'Rotaract',
  },
  {
    id: '12',
    clubCity: 'Berlin, Germany',
    districtNumber: '1940',
    clubSite: 'www.rotaryberlin.de',
    address:
      'Berlin Marriott Hotel, Inge-Beisheim-Platz 1, 10785 Berlin, Germany',
    meeting: 'Mon/1900',
    contact: '+49-30-5555-1111',
    charterDate: '01-Jan-2010',
    memberCount: 35,
    sponsoredClubs: 'Rotaract',
  },
  {
    id: '13',
    clubCity: 'Toronto, ON; Canada',
    districtNumber: '7070',
    clubSite: 'www.rotarytoronto.ca',
    address: 'Royal York Hotel, 100 Front St W, Toronto, ON, M5J 1E3, Canada',
    meeting: 'Tue/1230',
    contact: '+1-416-555-0123',
    charterDate: '10-Nov-2005',
    memberCount: 60,
    sponsoredClubs: 'Interact',
  },
  {
    id: '14',
    clubCity: 'Sydney, Australia',
    districtNumber: '9675',
    clubSite: 'www.rotarysydney.org.au',
    address:
      'Sydney Opera House, Bennelong Point, Sydney, NSW, 2000, Australia',
    meeting: 'Fri/1300',
    contact: '+61-2-5555-0123',
    charterDate: '05-Feb-2008',
    memberCount: 45,
    sponsoredClubs: 'Rotaract',
  },
  {
    id: '15',
    clubCity: 'Cape Town, South Africa',
    districtNumber: '9350',
    clubSite: 'www.rotarycapetown.org',
    address:
      'Cape Town International Convention Centre, 1 Lower Long St, Cape Town, 8001, South Africa',
    meeting: 'Thu/1900',
    contact: '+27-21-555-0123',
    charterDate: '30-Mar-2011',
    memberCount: 37,
    sponsoredClubs: 'Interact',
  },
  {
    id: '16',
    clubCity: 'Mumbai, India',
    districtNumber: '3141',
    clubSite: 'www.rotarymumbai.org',
    address: 'The Taj Mahal Palace, Apollo Bunder, Mumbai, MH, 400001, India',
    meeting: 'Sat/1230',
    contact: '+91-22-5555-0198',
    charterDate: '18-Aug-2007',
    memberCount: 42,
    sponsoredClubs: 'Rotaract',
  },
  {
    id: '17',
    clubCity: 'Rio de Janeiro, Brazil',
    districtNumber: '4570',
    clubSite: 'www.rotaryriodejaneiro.com',
    address:
      'Copacabana Palace Hotel, Avenida Atlântica, 1702 - Copacabana, Rio de Janeiro - RJ, 22021-001, Brazil',
    meeting: 'Wed/1900',
    contact: '+55-21-5555-0198',
    charterDate: '25-Oct-2009',
    memberCount: 33,
    sponsoredClubs: 'Interact',
  },
  {
    id: '18',
    clubCity: 'Mexico City, Mexico',
    districtNumber: '4170',
    clubSite: 'www.rotarymexicocity.org',
    address:
      'Hotel Presidente Intercontinental, Campos Eliseos 218, Polanco, 11560 Mexico City, Mexico',
    meeting: 'Tue/1900',
    contact: '+52-55-5555-0198',
    charterDate: '15-Sep-2012',
    memberCount: 28,
    sponsoredClubs: 'Rotaract',
  },
];
