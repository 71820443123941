import * as React from 'react';
import { FC, useState } from 'react';

import Button from '@rotaryintl/harris-button';
import TextField from '@rotaryintl/harris-form-basic/dist/TextField/TextField';
import { Form, Formik } from 'formik';

import DistrictCard from '@presenters/web/components/OfficialDirectory/District/DistrictCard';

import { useTranslation } from '@external/react-i18next';

const DistrictSection: FC = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useState('');
  const initialDistrict = {
    DistrictNumber: '',
  };
  return (
    <div className="m-0">
      <div>
        <Formik
          initialValues={initialDistrict}
          onSubmit={values => {
            const { DistrictNumber } = values;
            setSearchParams(DistrictNumber);
          }}
          onReset={() => {
            setSearchParams('');
          }}
        >
          {({ values, submitForm, resetForm }) => (
            <div>
              <Form>
                <div className="tablet:w-3/5">
                  <TextField
                    label={t(
                      'official.directory.district-text-label',
                      'District number'
                    )}
                    icon="search"
                    name="DistrictNumber"
                    type="search"
                    placeholder={t(
                      'official.directory.district-text-placeholder',
                      'Search district Number'
                    )}
                    description={t(
                      'official.directory.district-text-description',
                      'For the best search results, please include at least the first two numbers of the district.'
                    )}
                  />
                </div>
              </Form>
              <div className="flex flex-row gap-6 pt-3 pb-2">
                <Button clickHandler={submitForm}>
                  {t('official.directory.district-search-button', 'Search')}
                </Button>
                {values.DistrictNumber && (
                  <Button clickHandler={resetForm} size="md" variant="text">
                    {t('official.directory.district-reset-button', 'Reset')}
                  </Button>
                )}
              </div>
            </div>
          )}
        </Formik>
      </div>
      <div className="my-4 tablet:my-16">
        {searchParams && <DistrictCard searchParams={searchParams} />}
      </div>
    </div>
  );
};
export default DistrictSection;
