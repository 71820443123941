import * as React from 'react';
import { FC, SyntheticEvent, useEffect, useState } from 'react';

import Alert from '@rotaryintl/harris-alert';
import Pagination from '@rotaryintl/harris-pagination';

import ClubsByCityResults from '@presenters/web/components/OfficialDirectory/Club/ClubsByCityResults';
import {
  CLUBS_CURRENT_PAGE,
  CLUBS_PAGE_SIZE,
  CLUBS_PAGINATION_VARIANT,
} from '@presenters/web/components/OfficialDirectory/constants';
import { clubsByCity } from '@presenters/web/components/OfficialDirectory/MockData/ClubsByCity';
import LoadingComponent from '@presenters/web/components/OfficialDirectory/Others/LoadingComponent';
import NoResultFound from '@presenters/web/components/OfficialDirectory/Others/NoResultFound';

import { useTranslation } from '@external/react-i18next';

import {
  ClubByCity,
  ClubsByCityCardProps,
  LanguageCode,
} from '@typings/official-directory';

const ClubsByCityCard: FC<ClubsByCityCardProps> = ({ searchParams }) => {
  const { t, i18n } = useTranslation();
  const langCode = i18n.language.toLowerCase() as LanguageCode;
  const [expandedCardId, setExpandedCardId] = useState<string | null>(null);
  const [searchData, setSearchData] = useState<ClubByCity[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(CLUBS_CURRENT_PAGE);

  const pageSize = CLUBS_PAGE_SIZE;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, searchData.length);
  const currentPageData = searchData.slice(startIndex, endIndex);

  const loading = false;

  useEffect(() => {
    if (searchParams?.trim() === '') {
      setSearchData([]);
    } else {
      const filterData = clubsByCity.filter(club =>
        club?.address?.toLowerCase().includes(searchParams.toLowerCase())
      );
      setSearchData(filterData);
      setCurrentPage(CLUBS_CURRENT_PAGE);
    }
  }, [searchParams]);

  const onClick = (id: string) => {
    setExpandedCardId(prevId => (prevId === id ? null : id));
  };

  const onPageChange = (event: SyntheticEvent, pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return <LoadingComponent />;
  }

  if (!searchData || searchData.length === 0) {
    return <NoResultFound searchParams={searchParams} />;
  }

  return (
    <div className="my-3">
      <div className="py-2">
        <p className="text-xs">
          {t('official.directory.clubs-by-city-pagination-title-1', 'Showing')}{' '}
          <span className="font-bold">{startIndex + 1}</span>-
          <span className="font-bold">{endIndex}</span>{' '}
          {t('official.directory.clubs-by-city-pagination-title-2', 'of')}{' '}
          <span className="font-bold">{searchData.length}</span>{' '}
          {t(
            'official.directory.clubs-by-city-pagination-title-3',
            'results for'
          )}{' '}
          {searchParams}
        </p>
      </div>
      <div className="my-0">
        <Alert variant="info" isDismissible={false}>
          {t(
            'official.directory.clubs-by-city-results-alert',
            `Please visit the club website or contact the club directly to confirm
          meeting details. Information submitted to Rotary International may not
          be reflected immediately in search results.`
          )}
        </Alert>
      </div>
      <div>
        {currentPageData.map(club => (
          <ClubsByCityResults
            key={club.id}
            club={club}
            expandedCardId={expandedCardId}
            onClick={onClick}
          />
        ))}
      </div>
      <div>
        <Pagination
          currentPage={currentPage}
          lang={langCode}
          onPageChange={onPageChange}
          pageSize={pageSize}
          siblingCount={CLUBS_CURRENT_PAGE}
          totalCount={searchData.length}
          variant={CLUBS_PAGINATION_VARIANT}
        />
      </div>
    </div>
  );
};

export default ClubsByCityCard;
