import * as React from 'react';
import { FC, useEffect, useState } from 'react';

import Alert from '@rotaryintl/harris-alert';
import Pagination from '@rotaryintl/harris-pagination';

import {
  DISTRICTS_CURRENT_PAGE,
  DISTRICTS_PAGE_SIZE,
  DISTRICTS_PAGINATION_VARIANT,
} from '@presenters/web/components/OfficialDirectory/constants';
import DistrictCardResults from '@presenters/web/components/OfficialDirectory/District/DistrictCardResults';
import { districts } from '@presenters/web/components/OfficialDirectory/MockData/Districts';
import NoResultFound from '@presenters/web/components/OfficialDirectory/Others/NoResultFound';

import { useTranslation } from '@external/react-i18next';

import {
  District,
  DistrictCardProps,
  LanguageCode,
} from '@typings/official-directory';

const DistrictCard: FC<DistrictCardProps> = ({ searchParams }) => {
  const { t, i18n } = useTranslation();
  const langCode = i18n.language.toLowerCase() as LanguageCode;
  const [expandedCardId, setExpandedCardId] = useState<string | null>(null);
  const [searchData, setSearchData] = useState<District[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(
    DISTRICTS_CURRENT_PAGE
  );

  const pageSize = DISTRICTS_PAGE_SIZE;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, searchData.length);
  const currentPageData = searchData.slice(startIndex, endIndex);

  useEffect(() => {
    if (searchParams?.trim() === '') {
      setSearchData([]);
    } else {
      const filterData = districts.filter(district =>
        district?.districtNumber?.startsWith(searchParams)
      );
      setSearchData(filterData);
      setCurrentPage(DISTRICTS_CURRENT_PAGE);
    }
  }, [searchParams]);

  const onClick = (id: string) => {
    setExpandedCardId(prevId => (prevId === id ? null : id));
  };

  const onPageChange = (event: React.SyntheticEvent, pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  if (!searchData || searchData.length === 0) {
    return <NoResultFound searchParams={searchParams} />;
  }

  return (
    <div>
      <div className="py-2">
        <p className="text-xs">
          {t('official.directory.district-pagination-title-1', 'Showing')}{' '}
          <span className="font-bold">{startIndex + 1}</span>-
          <span className="font-bold">{endIndex}</span>{' '}
          {t('official.directory.district-pagination-title-2', 'of')}{' '}
          <span className="font-bold">{searchData.length}</span>{' '}
          {t('official.directory.district-pagination-title-3', 'results for ')}
          {searchParams}
        </p>
      </div>
      <div className="my-0">
        <Alert variant="info" isDismissible={false}>
          {t(
            'official.directory.district-results-alert',
            `Please visit the club website or contact the club directly to confirm
          meeting details. Information submitted to Rotary International may not
          be reflected immediately in search results.`
          )}
        </Alert>
      </div>
      <div>
        {currentPageData.map(district => (
          <DistrictCardResults
            key={district.id}
            district={district}
            searchData={searchData}
            expandedCardId={expandedCardId}
            onClick={onClick}
          />
        ))}
      </div>
      <div>
        <Pagination
          currentPage={currentPage}
          lang={langCode}
          onPageChange={onPageChange}
          pageSize={pageSize}
          siblingCount={DISTRICTS_CURRENT_PAGE}
          totalCount={searchData.length}
          variant={DISTRICTS_PAGINATION_VARIANT}
        />
      </div>
    </div>
  );
};

export default DistrictCard;
